<template>
  <div class="login">
    <div class="logo">
      <div class="inner">
        <div>线上考试平台</div>
      </div>
    </div>
    <div class="loginframe">
      <div class="ellipse"></div>
      <div class="login-form">
        <el-form :model="loginForm" :rules="rules" ref="ruleForm" label-width="60px" class="demo-ruleForm">
          <el-form-item label="账号" prop="account">
            <el-input v-model="loginForm.account"></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="password">
            <el-input v-model="loginForm.password" show-password></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="doLogin('ruleForm')" class="login-btn">登录</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <!-- <i class="iconfont icon-tuichu"></i> -->
    <div class="company">
      <p>
        安徽省人工智能学会 版权所有
      </p>
      <p>
        地址：合肥市经开区丹霞路合肥工业大学翡翠湖校区 翡翠科技楼705 邮编：230009 传真：0551-62902373 邮箱：aaai_ah@163.com
      </p>
    </div>
  </div>
</template>

<script>
import Footer from '@views/studentModule/Footer';

export default {
  components: {
    Footer,
  },
  data() {
    return {
      formName: 'ruleForm',
      loginForm: {
        account: '',
        password: '',
      },
      submitBtn: {
        loading: false,
      },
      rules: {
        account: [{ required: true, message: '账号不能为空', trigger: 'blur' }],
        password: [{ required: true, message: '密码不能为空', trigger: 'blur' }],
      },
    };
  },
  methods: {
    async doLogin() {
      this.submitBtn.loading = true;
      setTimeout(() => {
        this.submitBtn.loading = false;
      }, 2000);
      this.$refs[this.formName].validate(async (valid) => {
        if (valid) {
          const result = await this.Api.login.stuLogin(this.loginForm);
          if (result.code !== '200') {
            this.$message.error('账号或密码错误');
            return;
          }
          const loginInfo = result.data;
          const token = loginInfo.token;
          const user = loginInfo.user;
          window.localStorage.setItem('token', token);
          window.localStorage.setItem('user', JSON.stringify(user));
          this.$router.push('/student/exams');
        } else {
          this.$message.error('请输入账号和密码');
          return false;
        }
      });
    },
  },
  mounted() {
    try {
      const user = window.sessionStorage.getItem('user');
      const userInfo = JSON.parse(user);
      if (userInfo) {
        if (userInfo.type === 2) {
          this.$router.push('/student/exams');
        }
      }
    } catch (e) {
      console.error('-------------用户未登录', e);
    }
  },
};
</script>

<style lang="scss" scoped>
.login {
  width: 100%;
  height: 100%;
  background: url('../../assets/images/login/background.png');
  background-size: 100%;
  position: relative;
  overflow: hidden;
}

.logo {
  width: 40vw;
  height: 30vw;
  background: url('../../assets/images/login/logo.png') no-repeat;
  position: absolute;
  background-size: contain;
  left: 15%;
  top: 15%;

  .inner {
    width: 100%;
    height: 100%;
    position: relative;

    div {
      user-select: none;
      font-family: Adobe Heiti Std R;
      font-size: 24px;
      letter-spacing: 8px;
      // color: #8390a0;
      color: #fff;
      text-align: center;
      line-height: 50px;
      width: 556px;
      height: 50px;
      background-color: rgb(53, 69, 90);
      border-radius: 8px;
      position: absolute;
      // top: 520px;
      bottom: 0px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.loginframe {
  width: 387px;
  height: 322px;
  background-color: #f6f6f6;
  border: #1c84f9 1px solid;
  position: absolute;
  top: 25%;
  left: 65%;
}

.ellipse {
  width: 705px;
  height: 25px;
  background: url('../../assets/images/login/ellipse.png');
  position: absolute;
  left: -153px;
  top: -4px;
}

.login-form {
  display: block;
  padding: 50px 33px;
  margin-top: 30px;
}

.company {
  position: absolute;
  bottom: 0;
  height: 88px;
  background: #555555;
  z-index: 1;
  width: 100%;
  // display: flex;
  // align-items: center;

  p {
    width: 100%;
    text-align: center;
    color: #fff;

    a {
      text-decoration: none;
      color: #fff;
    }
  }
}

.login-btn {
  width: 80%;
}
</style>
